
// 饼图
export const optionIndexPie = chartdata => {

  //颜色16进制换算rgba,添加透明度
  function hexToRgba (hex, opacity) {
    return (
      'rgba(' +
      parseInt('0x' + hex.slice(1, 3)) +
      ',' +
      parseInt('0x' + hex.slice(3, 5)) +
      ',' +
      parseInt('0x' + hex.slice(5, 7)) +
      ',' +
      opacity +
      ')'
    );
  }

  let backgroundColor = 'rgba(0,0,0,0)';
  let radius = ['50%', '54%'];
  // 颜色系列
  let color = ['#37FFC9', '#FFE777', '#19D6FF', '#32A1FF', '#cccccc', '#ff1111'];
  let color1 = [],
    color2 = [],
    color3 = [];
  // 设置每层圆环颜色和添加间隔的透明色
  color.forEach((item) => {
    color1.push(item, 'transparent');
    color2.push(hexToRgba(item, 0.7), 'transparent');
    color3.push(hexToRgba(item, 0.4), 'transparent');
  });
  let data1 = [];
  let sum = 0;
  // 根据总值设置间隔值大小
  chartdata.forEach((item) => {
    sum += Number(item.value);
  });
  if (chartdata.length < 2) {
    data1 = chartdata
  } else {

    // 给每个数据后添加特定的透明的数据形成间隔
    chartdata.forEach((item, index) => {

      if (item.value !== 0) {

        data1.push(item, {
          name: '',
          value: sum / 70,
          labelLine: {
            show: false,
            lineStyle: {
              color: 'transparent',
            },
          },
          itemStyle: {
            color: 'transparent',
          },
        });
      }
    });
  }

  // 每层圆环大小
  let radius2 = [Number(radius[1].split('%')[0]) + 0 + '%', Number(radius[1].split('%')[0]) + 4 + '%'];
  let radius3 = [Number(radius[1].split('%')[0]) + 4 + '%', Number(radius[1].split('%')[0]) + 8 + '%'];
  let option = {
    backgroundColor: backgroundColor,
    grid: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      containLabel: true,
    },
    tooltip: {
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: 5,
      show: (data) => {
        return data && data.name ? true : false
      },
      formatter: (params) => {
        return params.name !== '' ?
          params.name + ' : ' + params.value + '亩' + '\n' + '(' + params.percent.toFixed(0) + '%)' : null

      },
    },
    series: [
      // 最外层圆环
      {
        type: 'pie',
        radius: radius3,
        center: ['50%', '50%'],
        hoverAnimation: false,
        startAngle: 90,
        selectedMode: 'single',
        selectedOffset: 0,
        itemStyle: {
          normal: {
            color: (params) => {

              let i = params.dataIndex > 11 ? params.dataIndex % 12 : params.dataIndex
              return color1[i];
            },
          },
        },
        label: {
          show: true,
          position: 'outside',
          formatter: (params) => {

            let zzb = 0;
            data1.forEach((item, index) => {
              // 当前值一半加上前面的值是否大于50%（判断label朝向）
              if (index <= params.dataIndex && item.name != '') {
                if (index == params.dataIndex) {
                  zzb += Number(item.value) / 2;
                } else {
                  zzb += Number(item.value);
                }
              }
            });
            if (params.name != '') {
              // 若当前值一半加上前面的值的占比大于0.5三角形朝右，相反朝左
              if (zzb / sum > 0.5) {
                return '{txt|' + params.percent.toFixed(0) + '%}' + '\n{san|▶}';
              } else {
                return '{txt|' + params.percent.toFixed(0) + '%}' + '\n{san|◀}';
              }
            }
          },
          align: 'left',
          padding: [0, -50, 10, -60],
          rich: {
            txt: {
              color: '#fff',
              width: 10,
              height: 10,
              padding: [-10, 10, 0, 24],
            },
            san: {
              color: '#00C395',
              padding: [-30, -3, -20, 8],
            },
          },
        },
        labelLine: {
          show: true,
          length: 15,
          length2: 70,
          lineStyle: {
            color: '#fff',
            width: 2,
          },
        },
        data: data1,
        z: 666,
      },
      {
        type: 'pie',
        radius: radius2,
        center: ['50%', '50%'],
        hoverAnimation: false,
        startAngle: 90,
        selectedMode: 'single',
        selectedOffset: 0,
        itemStyle: {
          normal: {
            color: (params) => {
              let i = params.dataIndex > 11 ? params.dataIndex % 12 : params.dataIndex
              return color2[i];
            },
          },
        },
        label: {
          show: false,
          formatter: '{b}' + ' ' + '{c}',
        },
        data: data1,
        z: 666,
      },
      {
        type: 'pie',
        radius: radius,
        center: ['50%', '50%'],
        hoverAnimation: false,
        startAngle: 90,
        selectedMode: 'single',
        selectedOffset: 0,
        itemStyle: {
          normal: {
            color: (params) => {
              let i = params.dataIndex > 11 ? params.dataIndex % 12 : params.dataIndex

              return color3[i];
            },
          },
        },
        label: {
          show: false,
          formatter: '{b}' + ' ' + '{c}',
        },
        data: data1,
        z: 666,
      },
    ],
  };
  return option

}
// 含最大小值 折线图
export const optionMaxMinLine = (data) => {

  let option = {

    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: 5,
      formatter: function (arr) {
        let param = arr[0]
        return param.marker + " " + param.name + "<br>"
          + "&nbsp;&nbsp;&nbsp;&nbsp;" + param.seriesName + ":&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;" + param.value + data.paramUnit + "<br>";
      }

    },
    grid: {
      top: '26%',
      left: '6%',
      right: '6%',
      bottom: '18%'
    },
    xAxis: {
      //  type: 'category',
      data: data.xAxis,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#999', //X轴文字颜色
          fontSize: 10
        },
        formatter: function (params) {
          var newParamsName = ''; // 最终拼接成的字符串
          var paramsNameNumber = params.length; // 实际标签的个数
          var provideNumber = params.indexOf(' '); // 每行能显示的字的个数
          var str1 = params.substring(0, provideNumber);
          var str2 = params.substring(provideNumber, paramsNameNumber);
          newParamsName = str1 + '\n' + str2;

          return newParamsName;
        }
      },
    },
    yAxis: {
      name: data.paramUnit,
      nameTextStyle: {
        color: "#00C395",
        fontSize: 14,
      },
      type: 'value',
      axisLabel: {
        formatter: '{value}'
      }
    },
    series: [
      {
        name: data.paramName,
        type: 'line',
        color: ['#00C395'],
        markPoint: {
          data: [
            {
              type: 'max', name: '最大值', label: {
                formatter: '{c}' + data.paramUnit,
                color: "#fff"
              }
            },
            {
              type: 'min', name: '最小值', label: {
                formatter: '{c}' + data.paramUnit,
                color: "#fff"
              }
            }
          ]
        },
        markLine: {
          data: [
            {
              type: 'average', name: '平均值', label: {
                formatter: '{c}' + data.paramUnit,
              }
            }
          ]
        },
        data: data.yAxis
      },]
  };
  return option
}
// 多类型折线图
export const optionMoreline = (data) => {

  let lineY = [], legendName = []
  if (data.yAxis && data.yAxis.length) {
    data.yAxis.forEach(item => {

      var info = {
        name: item.name,
        type: 'line',
        smooth: false,
        // areaStyle: {
        //     normal: {

        //         shadowColor: 'rgba(0, 0, 0, 0.1)',
        //         shadowBlur: 10
        //     }
        // },
        symbol: 'circle',
        symbolSize: 5,
        data: item.yAxis
      }
      legendName.push(item.name)
      lineY.push(info)
    })
  }
  let option = {
    backgroundColor: '#15232D',
    legend: {
      top: '4%',
      right: '8%',
      data: legendName,
      textStyle: {
        fontSize: 14,
        color: 'F1F1F3'
      },
    },
    toolbox: {
      top: '2%',
      right: '4%',
      feature: {
        restore: { show: true },
        saveAsImage: { show: true }
      }
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: 10,
      show: true,
      formatter: (params) => {
        if (!params || !params.length) return
        let result = params[0].name + "<br>"
        params.map((param, index) => {
          result += "&nbsp;&nbsp;" + param.marker + " " + param.seriesName + ":&nbsp;&nbsp;&nbsp;" + param.value + data.paramUnit + "<br>";

        })
        return result
      },
    },
    grid: {
      top: '24%',
      left: '4%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: {
      show: true,
      type: 'category',
      boundaryGap: false,
      data: data.xAxis,
      axisLabel: {
        textStyle: {
          color: 'rgb(0,253,255,0.6)'
        },

      },
      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgb(0,253,255,0.2)'
        }
      }
    },
    yAxis: {
      show: true,

      type: 'value',
      axisLabel: {
        formatter: '{value}' + data.paramUnit,
        textStyle: {
          color: 'rgb(0,253,255,0.6)'
        }
      },
      splitLine: {
        lineStyle: {
          color: 'rgb(23,255,243,0.1)'
        }
      },

      axisLine: {
        show: true,
        lineStyle: {
          color: 'rgb(0,253,255,0.2)'
        }
      }
    },
    series: lineY
  }
  return option
}
// 动物分析饼图
export const optionAnimalPie = data => {
  let legendData = [], totalNub = 0, seriesData = []
  let colorList = ['#73DDFF', '#73ACFF', '#FDD56A', '#FDB36A', '#FD866A', '#9E87FF', '#58D5FF'];
  if (data && data.length) {
    data.forEach(item => {
      legendData.push(item.baseName)
      totalNub += item.liveStockNum
      seriesData.push({
        name: item.baseName,
        value: item.liveStockNum
      })
    })
  } else return

  let option = {

    title: [
      {
        text: '总计',
        x: '50%',
        y: '43%',
        textAlign: 'center',
        textStyle: {
          fontSize: '16',
          fontWeight: '500',
          color: '#fff',
          textAlign: 'center',
        },
      }, {
        text: totalNub + '头',
        left: '50%',
        top: '51%',
        textAlign: 'center',
        textStyle: {
          fontSize: '18',
          fontWeight: '500',
          color: '#00C395',
          textAlign: 'center',
        },
      }
    ],
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: 5,
      formatter: function (parms) {
        var str = parms.marker + "" + parms.data.name + "</br>" +
          "数量：" + parms.data.value + "头</br>" +
          "占比：" + parms.percent + "%";
        return str;
      }
    },
    series: [{
      type: 'pie',
      z: 3,
      center: ['50%', '50%'],
      radius: ['25%', '45%'],
      clockwise: true,
      avoidLabelOverlap: true,
      hoverOffset: 15,
      itemStyle: {
        normal: {
          color: function (params) {
            return colorList[params.dataIndex]
          }
        }
      },
      label: {
        show: true,
        position: 'outside',
        formatter: '{a|{b}：{d}%}\n{hr|}',
        color: '#fff',
        rich: {
          hr: {
            backgroundColor: 't',
            borderRadius: 3,
            width: 3,
            height: 3,
            padding: [3, 3, 0, -12]
          },
          a: {
            padding: [-30, 15, -20, 15]
          }
        }
      },
      labelLine: {
        normal: {
          length: 20,
          length2: 30,
          lineStyle: {
            width: 1
          }
        }
      },
      data: seriesData
    }, {
      name: '第一层环',
      type: 'pie',
      z: 2,
      tooltip: {
        show: false
      },
      center: ['50%', '50%'],
      radius: ['45%', '58%'],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          // shadowBlur: 40,
          // shadowColor: 'rgba(0, 255, 255,.3)',
          color: 'rgba(23,38,48,.8)',
        },
        emphasis: {
          color: 'rgba(23,38,48,.8)',
        }
      },
      label: {
        show: false
      },
      data: [100]
    }, {
      name: '第二层环',
      type: 'pie',
      z: 1,
      tooltip: {
        show: false
      },
      center: ['50%', '50%'],
      radius: ['58%', '76%'],
      hoverAnimation: false,
      clockWise: false,
      itemStyle: {
        normal: {
          // shadowBlur: 40,
          // shadowColor: 'rgba(0, 255, 255,.3)',
          color: 'rgba(28,42,52,.6)',
        },
        emphasis: {
          color: 'rgba(28,42,52,.6)',
        }
      },
      label: {
        show: false
      },
      data: [100]
    }]
  }
  return option
}
// 动物分析线图
export const optionAnimalLine = data => {
  let categoryData = [];
  let chartdata = [];
  if (data && data.length) {
    data.forEach(item => {
      categoryData.push(item.baseName)
      let nub = 0
      if (item.animalBreedDeaths && item.animalBreedDeaths.length) {
        item.animalBreedDeaths.forEach(val => nub += val.deathNum)
      }
      chartdata.push(nub)
    })

  } else return


  let option = {

    textStyle: {
      color: '#c0c3cd',
      fontSize: 14,
    },
    toolbox: {
      show: false,
      feature: {
        saveAsImage: {
          backgroundColor: '#031245',
        },
        restore: {},
      },
      iconStyle: {
        borderColor: '#c0c3cd',
      },
    },
    legend: {
      top: 10,
      itemWidth: 8,
      itemHeight: 8,
      icon: 'circle',
      left: 'center',
      padding: 0,
      textStyle: {
        color: '#c0c3cd',
        fontSize: 14,
        padding: [2, 0, 0, 0],
      },
    },
    color: ['#00D7E9', 'rgba(0, 215, 233, 0.9)'],
    grid: {
      containLabel: true,
      left: 40,
      right: 20,
      bottom: 40,
      top: 40,
    },
    xAxis: {
      nameTextStyle: {
        color: '#c0c3cd',
        padding: [0, 0, -10, 0],
        fontSize: 14,
      },
      axisLabel: {
        color: '#c0c3cd',
        fontSize: 14,
        interval: 0,
      },
      axisTick: {
        show: false,
        lineStyle: {
          color: '#384267',
          width: 1,
        },
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: '#335971',
        },
        show: true,
      },
      data: categoryData,
      type: 'category',
    },
    yAxis: {
      nameTextStyle: {
        color: '#c0c3cd',
        padding: [0, 0, -10, 0],
        fontSize: 14,
      },
      axisLabel: {
        color: '#c0c3cd',
        fontSize: 14,
      },
      axisTick: {
        lineStyle: {
          color: '#668092',
          width: 1,
        },
        show: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: '#335971',
          // "type": "dashed"
        },
      },
      axisLine: {
        lineStyle: {
          color: '#668092',
          width: 1,
          // "type": "dashed"
        },
        show: true,
      },
      name: '',
    },
    series: [
      {
        data: chartdata,
        type: 'bar',
        barMaxWidth: 'auto',
        barWidth: 20,
        itemStyle: {
          color: {
            x: 0,
            y: 1,
            x2: 0,
            y2: 0,
            type: 'linear',
            colorStops: [
              {
                offset: 0,
                color: '#00D7E9',
              },
              {
                offset: 1,
                color: 'rgba(0, 167, 233,0.3)',
              },
            ],
          },
        },
        label: {
          show: true,
          position: 'top',
          distance: 10,
          color: '#fff',
        },
      },
      {
        data: [1, 1, 1, 1, 1, 1],
        type: 'pictorialBar',
        barMaxWidth: '20',
        symbol: 'diamond',
        symbolOffset: [0, '50%'],
        symbolSize: [20, 10],
      },
      {
        data: chartdata,
        type: 'pictorialBar',
        barMaxWidth: '20',

        symbolPosition: 'end',
        symbol: 'diamond',
        symbolOffset: [0, '-50%'],
        symbolSize: [20, 12],
        zlevel: 2,
      },
    ],
    tooltip: {
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: 5,
      show: true,
      formatter: '{b}:{c}',
    },

  }
  return option
}
// 柱状折线混图
export const optionBarLine = (echarts, dataInfo, type) => {

  let data1 = null, data2 = null, data3 = null;
  let xData = [], legendData = []
  if (dataInfo) {
    let { xAxis, charts } = dataInfo
    xData = xAxis
    charts.map(item => {
      let name = `${item.name}(${item.unit})`
      item.name = name
      switch (name) {
        case `杀虫数量(${item.unit})`:
          data1 = item
          break;
        case `温度(${item.unit})`:
          data2 = item
          break;
        case `湿度(${item.unit})`:
          data3 = item
          break;
      }
      legendData.push(name)

    })
  }
  let option = {
    // title:{
    //     text: '环境参数',
    //     x: '2%',
    //     y:'4%',
    //     textStyle:{
    //         fontSize: '16px',
    //         color: '#fff'
    //     }
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: [20, 20, 20, 20],
    },
    grid: {
      borderWidth: 0,
      top: '28%',
      left: '6%',
      bottom: '12%',
      right: '4%',
      textStyle: {
        color: '#fff',
      },
    },
    legend: {
      top: '5%',
      right: '4%',
      data: legendData,
      textStyle: {
        color: "#ffffff"
      }
    },
    xAxis: [
      {
        type: 'category',
        triggerEvent: true,
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.5)',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#535766',
            type: 'dashed',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: '#999',
          formatter: function (params) {
            let arrParams = params.split(' ')
            return type ? arrParams[0] : arrParams[0] + '\n' + arrParams[1]
          }
        },
        data: xData,

      },
    ],
    yAxis: [
      {
        type: 'value',
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
      },
    ],
    series: [
      {
        name: data1 ? data1.name : '电击次数',
        type: 'bar',
        // "stack": "总量",
        barWidth: 15,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: "#40E0FD"
            },
            {
              offset: 1,
              color: "#28353F"
            }
            ])
          }
        },
        data: data1 ? data1.yAxis : [],
      },
      {
        name: data2 ? data2.name : '环境温度',
        type: 'line',
        symbol: 'circle',
        itemStyle: {
          //折线拐点标志的样式
          color: "#00C395"
        },
        lineStyle: {
          color: "#00C395"
        },

        areaStyle: {
          color: 'rgba(0,195,149,0.2)',
        },
        data: data2 ? data2.yAxis : [],
      },
      {
        name: data3 ? data3.name : '环境湿度',
        type: 'line',
        symbol: 'circle',
        itemStyle: {
          //折线拐点标志的样式
          color: "#ffa800"
        },
        lineStyle: {
          color: "#ffa800"
        },

        areaStyle: {
          color: 'rgba(255,168,0,0.2)',
        },
        data: data3 ? data3.yAxis : [],
      },
    ],
  }
  return option
}
// 多轴柱状折线混图
export const optionMoreAxisBarLine = (echarts, dataInfo, type) => {

  let data1 = null, data2 = null, data3 = null;
  let xData = [], legendData = []
  if (dataInfo) {
    let { xAxis, charts } = dataInfo
    xData = xAxis
    charts.map(item => {
      let name = `${item.name}(${item.unit})`
      item.name = name
      switch (name) {
        case `杀虫数量(${item.unit})`:
          data1 = item
          break;
        case `温度(${item.unit})`:
          data2 = item
          break;
        case `湿度(${item.unit})`:
          data3 = item
          break;
      }
      legendData.push(name)

    })
  }
  let option = {
    // title:{
    //     text: '环境参数',
    //     x: '2%',
    //     y:'4%',
    //     textStyle:{
    //         fontSize: '16px',
    //         color: '#fff'
    //     }
    // },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: [20, 20, 20, 20],
    },
    grid: {
      borderWidth: 0,
      top: '28%',
      left: '6%',
      bottom: '12%',
      right: '10%',
      textStyle: {
        color: '#fff',
      },
    },
    legend: {
      top: '5%',
      right: '4%',
      data: legendData,
      textStyle: {
        color: "#ffffff"
      }
    },
    xAxis: [
      {
        type: 'category',
        axisLine: {
          lineStyle: {
            color: 'rgba(255,255,255,.5)',
          },
        },
        axisLine: {
          show: false,
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#535766',
            type: 'dashed',
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: true,
          color: '#999',
          formatter: function (params) {
            let arrParams = params.split(' ')
            return type ? arrParams[0] : arrParams[0] + '\n' + arrParams[1]
          }
        },
        data: xData,

      },
    ],
    yAxis: [
      {
        type: 'value',
        name: '湿度',
        // nameLocation: 'start',
        nameTextStyle: {
          color: '#999',
          align: 'right'
        },
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
        axisLabel: {
          formatter: '{value} %RH'
        }
      },
      {
        type: 'value',
        nameLocation: 'end',
        name: '温度',
        // inverse: true,
        nameTextStyle: {
          color: '#999',
          align: 'left'
        },
        min: 0,
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
        axisLabel: {
          formatter: '{value} ℃'
        }
      },
      {
        type: 'value',
        name: '杀虫数量',
        // nameLocation: 'start',
        nameTextStyle: {
          color: '#999',
          align: 'left'
        },
        position: 'right',
        offset: 45,
        min: 0,
        axisLabel: {
          show: true,
          color: '#999',
        },
        splitLine: {
          show: true,
          lineStyle: {
            color: '#91939d',
            type: 'dashed',
          },
        },
        axisLabel: {
          formatter: '{value} 头'
        }
      }
    ],
    series: [
      {
        name: data1 ? data1.name : '电击次数',
        type: 'bar',
        // "stack": "总量",
        barWidth: 15,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: "#40E0FD"
            },
            {
              offset: 1,
              color: "#28353F"
            }
            ])
          }
        },
        yAxisIndex: 2,
        data: data1 ? data1.yAxis : [],
      },
      {
        name: data2 ? data2.name : '环境温度',
        type: 'line',
        symbol: 'circle',
        yAxisIndex: 1,
        itemStyle: {
          //折线拐点标志的样式
          color: "#00C395"
        },
        lineStyle: {
          color: "#00C395"
        },

        areaStyle: {
          color: 'rgba(0,195,149,0.2)',
        },
        data: data2 ? data2.yAxis : [],
      },
      {
        name: data3 ? data3.name : '环境湿度',
        type: 'line',
        symbol: 'circle',
        itemStyle: {
          //折线拐点标志的样式
          color: "#ffa800"
        },
        lineStyle: {
          color: "#ffa800"
        },

        areaStyle: {
          color: 'rgba(255,168,0,0.2)',
        },
        data: data3 ? data3.yAxis : [],
      },
    ],
  }
  return option
}
// 风向折线图
export const optionWindLine = data => {
  let yAxis = []
  let jtIcon = require('../assets/imgs/monitoring/defalut/jt.png')
  data.yAxis.map(val => {
    yAxis.push({
      value: val,
      symbol: 'image://' + jtIcon, // 风向符号
      symbolSize: 15, // 大小
      symbolOffset: [0, 0], // 偏移
      symbolRotate: val, // 角度
      itemStyle: {
        color: '#00C395',
        size: 20,
      },
    })
  })
  let paramValToWindText = paramVal => {

    let valArr = [
      {
        max: 112.5,
        min: 67.5,
        name: '北风'
      },
      {
        max: 157.5,
        min: 112.5,
        name: '西北风'
      },
      {
        max: 202.5,
        min: 157.5,
        name: '西风'
      },
      {
        max: 247.5,
        min: 202.5,
        name: '西南风'
      },
      {
        max: 292.5,
        min: 247.5,
        name: '南风'
      },
      {
        max: 337.5,
        min: 292.5,
        name: '东南风'
      },
      {
        max: 22.5,
        min: 337.5,
        name: '东风'
      },
      {
        max: 67.5,
        min: 22.5,
        name: '东北风'
      }
    ]
    let val = parseInt(paramVal)
    let resultName = '无风'
    valArr.some(item => {

      if (val > 337.5 || val <= 22.5) {
        resultName = '东风'
        return true
      }
      if (val > item.min && val <= item.max) {
        resultName = item.name
        return true
      }
    })

    return resultName

  }

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: 5,
      formatter: function (arr) {
        let param = arr[0]
        let windText = paramValToWindText(param.value)
        return param.marker + " " + param.name + "<br>"
          + "&nbsp;&nbsp;&nbsp;&nbsp;" + param.seriesName + ":&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp&nbsp;" + windText + "<br>";
      }

    },
    grid: {
      top: '26%',
      left: '6%',
      right: '6%',
      bottom: '18%'
    },
    xAxis: {
      //  type: 'category',
      data: data.xAxis,
      axisLabel: {
        show: true,
        textStyle: {
          color: '#999', //X轴文字颜色
          fontSize: 10
        },
        formatter: function (params) {
          var newParamsName = ''; // 最终拼接成的字符串
          var paramsNameNumber = params.length; // 实际标签的个数
          var provideNumber = params.indexOf(' '); // 每行能显示的字的个数
          var str1 = params.substring(0, provideNumber);
          var str2 = params.substring(provideNumber, paramsNameNumber);
          newParamsName = str1 + '\n' + str2;

          return newParamsName;
        }
      },
    },
    yAxis: {
      name: data.paramUnit,
      nameTextStyle: {
        color: "#00C395",
        fontSize: 14,
      },
      type: 'value',
      axisLabel: {
        formatter: '{value}'
      }
    },
    series: [
      {
        name: data.paramName,
        type: 'line',
        color: ['#00C395'],
        // markLine: {
        //         data: [
        //             {type: 'average', name: '平均值',label: {
        //                 formatter: '{c}' + data.paramUnit,
        //             }}
        //         ]
        // },
        data: yAxis
      },
    ]
  }
  return option
}
// 动物体温柱状对比值
export const optionAnimalTemp = (data) => {
  let list = data.traAnimalInfoTTmpAnas
  let maxVal = list[0].tempMax
  let minVal = list[0].tempMin || 0
  let xAxisList = []
  let seriesList = []
  list.map((item) => {
    xAxisList.push(item.name || '')
    seriesList.push({
      value: item.temperature,
      itemStyle: {
        color: "#3cefff"
      }
    })
  })
  let option = {
    backgroundColor: '#15232D',
    grid: {
      top: '24%',
      left: '4%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    dataZoom: [
      {
        type: "inside", //slider表示有滑动块的，inside表示内置的
        show: false,
        xAxisIndex: 0,
        start: 0,
        end: 100,
      },
    ],
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: [10, 10, 10, 10],
      formatter: (params) => {
        return params[2].name + '：' + `${params[2].value ? params[2].value : '暂无数据'}` + `${params[2].value ? '℃' : ''}`
      }
    },
    xAxis: [{
      type: "category",
      data: xAxisList,
      axisTick: {
        alignWithLabel: true
      },
      nameTextStyle: {
        color: "#999"
      },
      axisLine: {
        lineStyle: {
          color: "rgb(0,253,255,0.2)"
        }
      },
      axisLabel: {
        textStyle: {
          color: "#999"
        }
      }
    }],
    yAxis: [{
      type: "value",
      axisLabel: {
        textStyle: {
          color: "#999"
        },
        formatter: "{value}℃"
      },
      splitLine: {
        lineStyle: {
          color: "rgb(0,253,255,0.2)"
        }
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgb(0,253,255,0.2)"
        }
      }
    }],
    series: [
      {
        name: "",
        type: 'pictorialBar',
        symbolSize: [20, 10],
        symbolOffset: [0, -5],
        symbolPosition: 'end',
        z: 12,
        //"barWidth": "20",
        label: {
          normal: {
            show: true,
            position: "top",
            formatter: (params) => {
              let val = Number(params.value)
              if (val > minVal && val < maxVal) {
                return val + '℃' + '( 正常 )'
              } else {
                return val + '℃' + '( 异常 )'
              }
            }
          }
        },
        data: seriesList
      },
      {
        name: '',
        type: 'pictorialBar',
        symbolSize: [20, 10],
        symbolOffset: [0, 5],
        // "barWidth": "20",
        z: 12,
        data: seriesList
      },
      {
        type: 'bar',
        itemStyle: {
          normal: {
            //color: '#14b1eb',
            opacity: .7
          }
        },
        //silent: true,
        barWidth: "20",
        //barGap: '-100%', // Make series be overlap
        data: seriesList,
        markLine: {
          symbol: 'none',
          label: {
            position: "middle",
            formatter: '{b}'
          },
          data: [{
            name: "最高值" + maxVal + '℃',
            yAxis: maxVal,//res.targetTwo,
            lineStyle: {
              color: "#ffa800"
            },
          },
          {
            name: "最低值" + minVal + '℃',
            yAxis: minVal,//res.targetTwo,
            lineStyle: {
              color: "#324cea"
            },
          }]
        }
      }
    ]
  }
  return option
}
// 动物步数饼状图
export const optionAnimalSteps = (data) => {

  let seriesList = []

  if (data.normal > 0) {
    seriesList.push({
      value: data.normal,
      name: '正常'
    })
  }
  if (data.sameAs > 0) {
    seriesList.push({
      value: data.sameAs,
      name: '一般'
    })
  }
  if (data.unusual > 0) {
    seriesList.push({
      value: data.unusual,
      name: '异常'
    })
  }

  var color = ['#57d5a9', '#ebb563', '#f56c6c']

  let option = {
    color: color,
    backgroundColor: '#15232D',
    grid: {
      top: '24%',
      left: '4%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    title: {
      text: '步数',
      left: 'center',
      top: '46%',
      textStyle: {
        fontSize: 18,
        color: '#fff',
        fontWeight: 'normal'
      }
    },
    tooltip: {
      trigger: 'item',
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      formatter: (params) => {
        return params.name + '：' + params.value + ' %'
      }
    },
    legend: {
      orient: 'vertical',
      right: 10,
      top: 10,
      textStyle: {
        color: "#fff",
        fontSize: 12
      }
    },
    series: [{
      type: 'pie',
      // roseType: 'area',
      radius: ['60%', '75%'],
      data: seriesList,
      label: {
        normal: {
          // formatter: '{font|{d}%}',
          formatter: (params) => {
            return params.data.value + '%' + '( ' + params.data.name + ' )'
          },
          rich: {
            font: {
              fontSize: 14,
              padding: [5, 0],
              color: '#fff'
            },
            hr: {
              height: 0,
              borderWidth: 1,
              width: '100%',
              borderColor: '#fff'
            }
          }
        },
      },
      labelLine: {
        lineStyle: {
          color: '#fff'
        },
      },
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0,0,0,0.5)'
        }
      }
    }]
  };
  return option
}
// 动物生理三柱状图
export const optionAnimalFeature = (data) => {
  let list = data.data
  let xAxisList = []
  let heightList = []
  let lengthList = []
  let weightList = []
  list.map((item => {
    xAxisList.push(item.name)
    heightList.push(item.height)
    lengthList.push(item.length)
    weightList.push(item.weight)
  }))

  let option = {
    backgroundColor: '#15232D',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',

        textStyle: {
          color: '#fff',
        },
      },
      color: '#fff',
      textStyle: {
        color: '#fff',
      },
      backgroundColor: '#28353F',
      borderColor: '#00C395',
      borderWidth: 2,
      padding: [10, 10, 10, 10],
    },
    dataZoom: [
      {
        type: "inside", //slider表示有滑动块的，inside表示内置的
        show: false,
        xAxisIndex: 0,
        start: 0,
        end: 100,
      },
    ],
    legend: {
      data: ['身高CM', '体重KG', '体长CM'],
      align: 'right',
      right: 10,
      textStyle: {
        color: "#fff"
      },
      itemWidth: 10,
      itemHeight: 10,
      itemGap: 35
    },
    grid: {
      top: '24%',
      left: '4%',
      right: '4%',
      bottom: '10%',
      containLabel: true
    },
    xAxis: [{
      type: 'category',
      data: xAxisList,
      axisLine: {
        show: true,
        lineStyle: {
          color: "rgb(0,253,255,0.2)",
          width: 1,
          type: "solid"
        }
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: "#999",
        }
      },
    }],
    yAxis: [{
      type: 'value',
      axisLabel: {
        formatter: '{value}'
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        show: false,
        lineStyle: {
          color: "#999",
          width: 1,
          type: "solid"
        },
      },
      splitLine: {
        lineStyle: {
          color: "rgb(0,253,255,0.2)",
        }
      }
    }],
    series: [{
      name: '身高CM',
      type: 'bar',
      data: heightList,
      barWidth: 10, //柱子宽度
      barGap: 1, //柱子之间间距
      itemStyle: {
        normal: {
          color: '#00C395',//
          opacity: 1,
        }
      }
    }, {
      name: '体重KG',
      type: 'bar',
      data: weightList,
      barWidth: 10,
      barGap: 1,
      itemStyle: {
        normal: {
          color: '#008cff',
          opacity: 1,
        }
      }
    }, {
      name: '体长CM',
      type: 'bar',
      data: lengthList,
      barWidth: 10,
      barGap: 1,
      itemStyle: {
        normal: {
          color: '#ebb563',
          opacity: 1,
        }
      }
    }]
  };
  return option
}